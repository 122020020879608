* {
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  overflow: hidden;
}

@media (min-width: 80rem) {
  body {
    flex-direction: row;
  }
}

.input-container, .art {
  width: 100%;
  height: 50%;
  position: relative;
}

body[data-supported="false"] .art {
  display: none;
}

@media (min-width: 80rem) {
  .input-container {
    width: 50%;
    height: auto;
    margin: auto;
  }

  .art {
    width: 50%;
    height: 100%;
  }
}

.video {
  width: var(--video-width);
  height: var(--video-height);
  object-fit: cover;
  object-position: top left;
}

body[data-supported="false"] .video {
  width: 100vw;
  height: 100vh;
  object-position: center;
  position: fixed;
  inset: 0;
}

.video-placeholder {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.face-debug {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.face-debug__box {
  width: var(--debug-box-width);
  height: var(--debug-box-height);
  transform: translate( var(--debug-box-translate-x), var(--debug-box-translate-y));
  background: #f003;
  border: 2px solid red;
  border-radius: .25rem;
  transition: transform 50ms, width 50ms, height 50ms;
  position: absolute;
  top: 0;
  left: 0;
}

.detections {
  max-height: 20vh;
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #0003;
  border-radius: 1rem;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  margin: 0;
  padding: 1rem;
  font-size: .8rem;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 50%;
  overflow-y: auto;
  transform: translateX(-50%);
}

@media (min-width: 40rem) {
  .detections {
    max-height: unset;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    font-size: 1rem;
    bottom: 2rem;
    overflow-y: visible;
  }
}

@media (min-width: 60rem) {
  .detections {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

body[data-supported="false"] .detections {
  display: none;
}

.detections__item {
  grid-template-columns: 6rem 1fr;
  display: grid;
}

@media (min-width: 60rem) {
  .detections__item--wide {
    grid-column: 1 / -1;
  }
}

.detections__title {
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-weight: bold;
  display: flex;
  position: relative;
}

.detections__title[data-side]:after {
  content: attr(data-side);
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: calc(50% + .7rem);
  transform: translate(100%, -50%);
}

.detections__data {
  min-height: 1rem;
  white-space: nowrap;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.detections__data span[data-score="good"] {
  color: #78dc78;
}

.detections__data span[data-score="bad"] {
  color: #dc7878;
}

.detections__icon {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: contain;
}

@media (min-width: 60rem) {
  .detections__icon {
    width: 2rem;
    height: 2rem;
  }
}

.face-debug {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.face-debug__box {
  width: var(--debug-box-width);
  height: var(--debug-box-height);
  transform: translate( var(--debug-box-translate-x), var(--debug-box-translate-y));
  background: #ff00004d;
  border: 2px solid red;
  border-radius: 1rem;
  transition: transform 50ms, width 50ms, height 50ms;
  position: absolute;
  top: 0;
  left: 0;
}

.art__image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.art__background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

.art__foreground-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)scale(1.2);
}

.art__foreground {
  height: 25vw;
  transform: rotateX(var(--art-foreground-rotate-x)) rotateY(var(--art-foreground-rotate-y)) scale(var(--art-foreground-scale));
  transform-origin: bottom;
  transition: transform .5s;
}

.webcam-selector {
  position: absolute;
  top: 3vh;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 80rem) {
  .webcam-selector {
    left: 25%;
  }
}

body[data-supported="false"] .webcam-selector {
  display: none;
}

.webcam-selector__select {
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #0006;
  border-color: #0000;
  border-right-style: solid;
  border-right-width: 1rem;
  border-radius: 1rem;
  padding: 1rem;
}

.notice {
  z-index: 10000;
  color: #fff;
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: #000c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7.5vw;
  display: none;
  position: fixed;
  inset: 0;
}

body[data-supported="false"] .notice {
  display: flex;
}

.notice__content {
  flex-direction: column;
  gap: 3vh;
  display: flex;
}

.notice__heading {
  text-align: center;
  font-size: max(3rem, 5vw);
}

.notice__icon {
  width: 20vw;
  min-width: 7.5rem;
  margin: 0 auto 5vh;
}

.notice__copy {
  font-size: max(1.5rem, 1vw);
}

.notice__list {
  flex-direction: column;
  gap: 2vh;
  font-size: max(1.5rem, 1vw);
  display: flex;
}

.notice__list-item {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.notice__list-item a {
  color: #fff;
}

.notice__list-item pre {
  white-space: break-spaces;
}

/*# sourceMappingURL=index.3b8758cd.css.map */
